.header-filter{
    form {
        display: flex;
    }
    .filters {
        // height: calc(40vh - 115px);
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
        // width: 100%;
        padding: 8px 0px 0px 16px;
        margin-right: 8px;
        >div{
            padding-left: 5px;
        }
      }
      .action-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
}

.SortSelecter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
}