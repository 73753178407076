.any-select {
  &.small-select {
    .bp3-input {
      height: 24px !important;
      font-size: 1rem;
    }
    .right-element {
      float: right;
      padding: 4px;
      color: #5c7080;
      cursor: pointer;
    }
  }
  input {
    // min-width: 196px;
    // margin-bottom: 8px;
  }
  .right-element {
    float: right;
    padding: 6px;
    color: #5c7080;
    cursor: pointer;
  }
  .bp3-input-group {
    // min-width: 196px;
  }
  .bp3-button {
    margin: 0 !important;
  }
  &__item {
    height: 24px !important;
  }
}

.reference-translation {
  display: flex;
  margin: 0px 0px 6px 8px;
  align-self: flex-start;
  color: #5c7080;
  text-decoration: underline;
}

.new-Item {
  background-color: lightgray;
}
.edit-button {
  display: inline;
  padding-left: 4px;
  height: 24px !important;
}

.autocomplete-label {
  display: flex;
}
