.expred-accordion {

    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px;

    &__header {
        padding-bottom: 8px;
    }
    &__add {
        text-align: left;
        padding-left: 16px;
    }
    &__attach {
        text-align: right;
        padding-right: 16px;
    }
   
    &__sample-btn{
        width:100%;
        justify-content: unset;
        .bp3-button-text {
            width: 100%;
        }
    }
    &__sample {
        display: flex;
        flex-direction: row;
    }
    &__sample-block {
        padding: 4px;
    }

    .sample-item {
        &__label {
            padding: 0 6px;
            font-style: italic;
        }
        &__value {
            flex-grow: 1;
        }
    }
}


