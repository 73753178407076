.dictionary-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0 0;

  &__criteria {
    padding: 0 8px;
  }
  &__value {
    padding: 0 8px;
  }
  &__buttons {
    padding: 0 8px;
  }
  &__add-button {
    margin-left: 8px;
  }
}
.dictionary-filter-list {
  display: flex;
  flex-direction: column;
  padding: 0 8px 8px;
}
.dictionary-filter-toolbar {
    display: flex;
    flex-direction: row;
    align-self: normal;
    margin-left: auto;
    padding: 24px 0;
}
.selected-filter {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  align-items: flex-start;

  &__header {
    display: flex;
    flex-direction: row;
    font-weight: bold;
  }
  &__remove-button {
    margin-left: 8px;
  }
  &__value {
    margin-top: 8px;
  }
}
