.create-role {
  padding: 0 24px;
  &_body {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }
  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .permit-table {
    padding: 8px 0;
  }
  .input-row {
    padding-bottom: 8px;
  }
}
