.any-text {
  //max-width: 184px;
    overflow: auto;
    max-height: 80px;
  &__container {
    display: flex;
    flex-direction: row;
  }
  .clear-btn {
    float: right;
    padding: 4px;
    color: #5c7080;
    cursor: pointer;
  }
  &.form-textarea {
    margin-bottom: 8px;
    padding: 6px 8px;
    font-size: 1rem;
    min-height: 40px;
  }
}

.form-group-text {
  input {
    min-width: 218px;
  }
}

