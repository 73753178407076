.group-delimeter {
  padding: 4px;
  margin: 8px;
  border-bottom: 1px solid #106ba3;
  font-size: 1rem;

  &__title {
    display: inline-block;
  }

  &__icon {
    color: #106ba3;
    float: right;
  }
}
