.form-group-text {
  input {
    min-width: 218px;
  }
}

.with-search {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 4px;
  border: 1px solid #B0C4DE;
  border-radius: 2px;

  &__form {
      padding-top: 4px;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
