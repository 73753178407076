.related-card {
    display: flex;
    flex-direction: row;

    &__info {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        min-height: 24px;
        padding: 4px;
        width: 100%;
        border-radius: 3px;
        box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
        0 0 0 0 rgba(19, 124, 189, 0),
        inset 0 0 0 1px rgba(16, 22, 26, 0.15),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
        font-size: 1rem;

        .bp3-tag {
            background: #fff !important;
            color: black;
            box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
            0 0 0 0 rgba(19, 124, 189, 0),
            inset 0 0 0 1px rgba(16, 22, 26, 0.15),
            inset 0 1px 1px rgba(16, 22, 26, 0.2);
          }
    }

    &__label {
        padding: 8px;
    }

    &__remove {
       float: right; 
    }
    &__btn-placeholder {
        margin: 24px 0;
    }

    &__detail {
        display: flex;
        flex-direction: row;

        b, i {
            padding: 0px 8px;
            font-size: 1rem;
        }
    }
}