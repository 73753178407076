.attach-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 10px;
  width: 150px;

  .bp3-file-upload-input {
    width: 0 !important;
    height: 30px !important;
    line-height: 30px !important;

    &:after {
      width: 90px !important;
      height: 30px !important;
      line-height: 30px !important;
      margin: 0 !important;
    }
  }
}

.attachment-type-select {
  margin: 10px;
}

.documents-panel{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 24px 24px;
  &_header{
    width: 100%;
    &_actions{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .header-button{
        align-items: center !important;
        display: flex;
        justify-content: flex-end;
        flex: 1;
      }
    }
  }
  &_footer{
    width: 100%;
    &_actions{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 90%;
      .footer-button{
        margin-right: 10px;
        background: #8aacc8 !important;
      }
    }
  }
}
