.form-field-portal {
  width: 300px;
  .bp3-popover-content {
      max-width: unset !important;
  }
}

.popup-field {
  display: flex;
  flex-direction: column;
  
  &__content {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
    columns: 2 auto;
  }

  &__content > div {
    margin-bottom: 4px;
    flex: 0 0 50%;
    max-width: 50%;
  }

  &__footer{
    align-self: flex-end;
    margin: 8px 0;
  }
}