.registration{
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: #EDFFFF;
  width: 443px;
  &__header{
    background: white;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid grey;
    height: 36px;
    color: black;
    display: flex;
    flex-direction: row;
    line-height: 1px;
    justify-content: center;
    align-items: center;
  }
  &__body{
    width: 450px;
    padding: 24px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1px;
  }
  &__form {
    width: 300px;
    .button-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  &__button {
    align-self: center;
    margin-top: 8px;
  }
  &__row {
    padding: 4px;
    .label {
      margin-top: 8px;
    }
  }
}
