.entity-field {
    display: flex;
    flex-direction: row;
    border: 1px solid #B0C4DE;
    border-radius: 2px;
    width: 100%;
    padding: 4px;
    margin: 4px;
    .add-button {
        margin-bottom: 8px;
        align-self: flex-end;
    }
    .remove-container{
      flex:1;
      display: flex;
      justify-content: flex-end;
    }
    .remove-button{
      margin-bottom: 8px;
    }
    &__new {
        justify-content: flex-end;
    }
  
    &__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  
    .button-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
  
      .bp3-button {
        margin: 0 2px !important;
      }
    }
  
    .block-result {
      background: #fff;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      min-height: 48px;
      padding-left: 2px;
      width: 100%;
      border-radius: 3px;
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
      0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15),
      inset 0 1px 1px rgba(16, 22, 26, 0.2);
  
      .bp3-tag {
        background: #fff !important;
        color: black;
        box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
        0 0 0 0 rgba(19, 124, 189, 0),
        inset 0 0 0 1px rgba(16, 22, 26, 0.15),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
      }
    }
}
  