.expred-form {
    width: 100%;
    margin: 4px;
    padding: 8px;

    &__actions {
        justify-content: flex-end;
        margin-right: 16px;
        margin-top: 4px;
        width: 100%;
    }
}