.any-text {
  //max-width: 184px;
  &__container {
    display: flex;
    flex-direction: row;
  }
  input {
    // min-width: 196px;
    // margin-bottom: 8px;
  }
  &.bp3-input-group {
    // min-width: 196px;
    // margin-bottom: 8px;
  }
  textarea {
    min-width: 400px;
  }
}

.form-group-text {
  input {
    min-width: 218px;
  }
}
.edit-btn {
  padding: 4px;
}
.required {
  color: red;
  padding: 2px;
}
