@import "../../../../../../scss/variables.scss";

.inner-datasheet {
    width: 100%;

    &__header {
        border: 1px solid silver;
        padding: 4px;
        color: $font-color;
        font-weight: 400;
        text-align: center;
        background-color: #fff;
    }

    &__row { 
        td {
            padding: 4px !important;
            text-align: left !important;
            background-color: #fff !important;
            vertical-align: middle !important;
        }
       
    }
}