.create-employees {
  padding: 24px;

  &__dialog {
    min-width: 900px;
  }
  &-body {
    display: flex;
    flex-direction: column;
    span {
      font-weight: normal !important;
    }
  }
  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__group {
      button {
        margin: 4px;
      }
    }
  }
}

.row {
  display: flex !important;
  flex-direction: row;
  margin-bottom: 8px;
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    color: #DCDCDC;
  }
  &-input {
    width: 100%;
  }
}
