@import "../../../scss/variables.scss";

.users-page {
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 48px);
  padding: $grid-medium;

  .scroll-table {
    border: 1px solid #eee;
    width: calc(100vw - 200px);

    .ant-table-body {
      height: calc(100vh - 200px);
    }
  }
}
