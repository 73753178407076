.help-info {
    background-color: #fff;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
    0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 16px;
}