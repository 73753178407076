.clear-btn {
  float: right;
  padding: 4px;
  color: #5c7080;
  cursor: pointer;
}
.any-text {
  //max-width: 184px;
  &__container {
    display: flex;
    flex-direction: row;
  }
  input {
    // min-width: 196px;
    height: 24px;
    font-size: 1rem;
  }
  &.bp3-input-group {
    // min-width: 196px;
    // margin-bottom: 8px;
  }
  textarea {
    min-width: 400px;
  }
}

.form-group-text {
  input {
    min-width: 218px;
  }
}

.bp3-control-group:not(.bp3-vertical) > *:not(.bp3-divider){
  margin-right:0!important;
}
