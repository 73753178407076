.footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 16px;
  padding: 4px;
  //box-shadow: 1px 1px 12px 1px rgba(16, 22, 26, 0.15);
  &_first, &_second{
    margin-right: 20px;
    max-height: 30px;
  }
  &_second{
    margin-right: 20px;
  }
  &_group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    button{
      width: 32px;
      height: 32px;
    }
  }
  .footer-pagination{
    margin-right: 24px;

    @media screen and (max-width: 600px) {
      button {
        font-size: 10px;
      }
    }
  }
  .count-select {
    margin-left: 24px;
    width: 80px;
    max-height: 30px;
  }
  .navigation-arrow {
    width: 32px;
    height: 32px;
  }
}
