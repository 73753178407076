.letters {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 24px;
    &__incoming {
        padding: 24px;
    }
    &__outgoing {
        padding: 24px;
    }
}