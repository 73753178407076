.tabs {
  width: 560px;
}

.change-password {
  padding: 24px;
  .div-password {
    width: 50%;
    //height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  &-form {
    &_body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      span {
        font-weight: normal !important;
      }
    }
  }
  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}


.row {
  display: flex !important;
  flex-direction: row;
  margin-bottom: 8px;
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    color: #DCDCDC;
  }
  &-input {
    width: 100%;
  }
}

.footer{
  display: flex;
  flex-direction: row;
  margin: 0 16px;
  padding: 4px;
  //box-shadow: 1px 1px 12px 1px rgba(16, 22, 26, 0.15);
  &_first, &_second{
    width: 10%;
    margin-right: 20px;
  }
  &_second{
    margin-right: 50px;
  }
  &_group{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    Button{
      width: 70px;
    }
  }
}
