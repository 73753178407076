.header-actions {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &--right{
    padding-right: 15px;
  }
  &__left{
    display: -webkit-box;
    div{
      margin-left: 8px;
    }
  }
}
.footer-actions {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.bp3-button:not(:first-child) {
  margin-left: 10px;
}

.header-actions__left button[type="button"]{
  padding: 6px;
}

.dialog__title{
  padding: 10px;
  justify-content: center;
}
