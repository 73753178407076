.menu-selected {
  background: #eee !important;
}
.bp3-popover-wrapper {
  align-items: center;
  cursor: pointer;
}
.bp3-navbar{
  display: flex;
}
.bp3-align-left {
  margin-right: auto;
}
.button-for-overflowed-menu {
  width: 100%;
  justify-content: flex-start !important;
}
.popover {
  display: flex !important;
}
