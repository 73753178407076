
.int-range {
  padding-right: 8px;
  &__container {
    display: flex;
    flex-direction: row;
  }
  input {
    height: 24px;
    min-width: 50px!important;
  }
}

