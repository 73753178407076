.list-layout {
  padding: 16px;
}

.ant-table-thead .ant-table-cell {
  padding: 0px !important;
  font-size: 0.9rem;
  text-align: center;
}
.ant-table-body {
  height: calc(100vh - 140px);
}
.ant-table-column-sorters {
  padding: 0px !important;
}

.buttons-view {
  padding-bottom: 8px;
}

.all-row {
  max-height: 50px !important;
  cursor: pointer;
  font-size: 1rem;
}
.row-header {
  background: #fafafa;
}
.selected-row {
  background-color: #c4ecff !important;
  .row-header {
    background: #c4ecff;
  }
}

.selected-row > td{
  background-color: #c4ecff !important;
}


.sorted-column > div > span
{
  width: inherit;
}
