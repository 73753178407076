@import "../../../../scss/variables.scss";

.general-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__pagination {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .ant-pagination {
            display: flex;
        }
    }
    &__selection {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: $grid-standard;
    }
    &__new {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
    }
    &__amount {
        white-space: nowrap;
        font-weight: $very-bold;
        padding: 0 $grid-medium;
    }
  }