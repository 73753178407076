.right-btn {
  float: right;
  color: #5c7080;
  padding: 6px;
  cursor: pointer;
}
.date-field {
  &_simple {
    input {
      min-width: 196px;
      font-size: 1rem;
    }
    .bp3-input-group {
      min-width: 196px;
    }
  }
  .bp3-input {
    height: 24px !important;
  }
  .right-btn {
    padding: 4px;
  }
}
.DayPicker-NavButton--prev {
  margin-right: auto!important;
}

.DayPicker-NavButton--next {
  margin-left: auto!important;
}
.date-range {
  padding-right: 8px;
  .bp3-input {
    max-width: 105px;
  }
}
