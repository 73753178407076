.empty {
    margin: 0px !important;
    display: flex!important;
    
    &__btn {
        padding: 2px 8px;
        border: 1px solid #eee;
        vertical-align: top;
        background-color: #eee;
        box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        -webkit-box-sizing: border-box !important;
        align-self: center;

        &.checked {
            border: 1px solid rgba(119, 119, 119, 0.75);
            background-color:rgba(238, 238, 238, 0.5);
        }
        &.unchecked {
            
        }
        &:hover {
            background-color:rgba(238, 238, 238, 0.5);
            border: 1px solid rgba(238, 238, 238, 0.5);
        }
    }

    .bp3-control-indicator {
        visibility: hidden;
    }
}
