.search-field {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    .result-block {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 4px;
        width: 100%;
        border-radius: 3px;
        box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
        0 0 0 0 rgba(19, 124, 189, 0),
        inset 0 0 0 1px rgba(16, 22, 26, 0.15),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    }
    .search-result {
        padding: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }
    }
    .search-result > div >button {
        margin: 3px;
    }
    .search-result >div:nth-child(2){
        overflow: auto;
        width: 100%;
    }
    .bp3-popover-target {
        width: 100%;
        cursor: default;
    }
    .bp3-popover-wrapper {
        width: 100%;
    }
}

.search-portal {
    .bp3-popover-content {
        max-width: unset !important;
    }
}