.distributor {
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px);

    &_header {
        width: 100%;
        padding: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &_input-button {
            width: 250px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &_showDeleted {
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            padding: 32px 0 0;
        }
    }

    &_body {
        padding: 0 24px 16px 24px;
    }
}
.column-header-cell{
  text-align: center;
  &:before{
    content: none !important;
  }
}
.column-cell{
  text-align: left;
}

