:root {
  --landscape-width: 25vw; // % OR vw for responsive window resizing!
  --portrait-width: 80vw;
  --header-height: 4rem;
  --secs: 0.4s;
  --devider: 320px;
}

.large-spinner {
  min-height: calc(100vh - 100px);
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-layout {
  display: flex; // Flexbox definition
  overflow: hidden; // Disable scrolling on layout
  width: 100%;

  // Style in common for sidebars and main area:
  .header {
    position: relative;
    height: var(--header-height);
    text-align: center; // Horizontal center
    .title {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .content {
    overflow: auto;
    box-sizing: border-box;
    //height: calc(100vh - var(--header-height));
  }
}

// Main area specific style:
#main {
  width: calc(100vw - var(--devider)) !important;
  background: var(--bg-main);
  flex-grow: 1;

  .header {
    background: linear-gradient(to right, var(--bg-left), var(--bg-right));
    @media (orientation: landscape) {
      .title {
        transition: left var(--secs), right var(--secs);

        &.left-closed {
          left: var(--header-height);
        }

        &.right-closed {
          right: var(--header-height);
        }
      }
    }
  }

  .display-row {
    &.list-header {
      padding: 24px 24px 8px 24px;
    }
  }

  .align-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .filter-close-icon {
    cursor: pointer;
    //padding: 16px 32px;
    margin: 0;
    span {
      padding-right: 8px;
    }
  }
}

// Style in common for both sidebars:
#left, #right {
  transition: width var(--secs);
  width: 0;
  @media (orientation: landscape) {
    &.open {
      //width: 300px;
      width: var(--devider);
    }
  }
  // <-- Landscape ONLY sidebars
  .icon {
    position: fixed;
    z-index: 10;
    width: var(--header-height);
    height: var(--header-height);
    line-height: var(--header-height);
    text-align: center;
    user-select: none;
    cursor: pointer;
  }

  .sidebar {
    transition: transform var(--secs); // <-- Portrait AND landscape!
    @media (orientation: portrait) {
      width: var(--portrait-width);
    }
    @media (orientation: landscape) {
      width: calc(1vw * var(--landscape-width));
    }

    .header {
      width: calc(100% - var(--header-height));
    }
  }
}


// Left sidebar specific style:
#left {
  z-index: 5;
  height: calc(100vh - 50px);
  border: 1px solid #DCDCDC;
  overflow-x: hidden;
  overflow-y: hidden;

  .sidebar {
    background: var(--bg-left);

    &.closed {
      transform: translateX(-100%);
    }

    // <-- Left portrait AND landscape!
    .header {
      left: var(--header-height);
    }
  }
}

