.form-tabs{
  .bp3-tab-list {
    margin: 0 32px;
    display: block;
  }
  .bp3-tab {
    background-color: #FFF !important;
    float: right;
    position: relative;
    color: #777;
    margin-top: 2px;
    margin-right: 0px !important;
    transition: all 250ms ease;
    z-index: 2;
    outline: none;
    overflow: unset;
    line-height: 22px;
  }
  .bp3-tab-panel {
    margin-top: 8px;
  }
  .bp3-tab::before,
  .bp3-tab::after {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    height: 100%;
    width: 22px; 	
    background-color: #FFF;
    transition: all 250ms ease;  
  }
  .bp3-tab::before {
    right: -12px;
    transform: skew(30deg, 0deg);
    // box-shadow: rgba(0,0,0,.1) 3px 0px 5px, inset rgba(255,255,255,.09) -1px 0;
    border-right: 1px solid rgba(92, 112, 128, 0.5);
    border-radius: 0 8px 0 0;
    z-index: 3;
  }
  .bp3-tab::after {
    left: -12px;
    transform: skew(-30deg, 0deg);
    border-left: 1px solid rgba(92, 112, 128, 0.5);
    // box-shadow: rgba(0,0,0,.1) -3px 0px 5px, inset rgba(255,255,255,.09) 1px 0;
    border-radius: 8px 0 0 0;
    z-index: 2;
  }
  .bp3-tab[aria-selected="true"] {
    z-index: 4;
  }
  .bp3-tab[aria-selected="true"],
  .bp3-tab[aria-selected="true"]:before,
  .bp3-tab[aria-selected="true"]:after {
    background-color: #5C7080 !important;
    color: #fff;
  }
  .bp3-tab[aria-selected="false"],
  .bp3-tab[aria-selected="false"]:before,
  .bp3-tab[aria-selected="false"]:after {
    background-color: #fff !important;
  }
  .bp3-tab:hover,
  .bp3-tab:hover::before,
  .bp3-tab:hover::after {
    background-color: #F4F7F9 !important;
    color: #444;
  }
  .bp3-tab-indicator-wrapper ~ .bp3-tab {
    background-color: #fff; 
  }
  
}