.Answer,.Comments ,.Message {
    display: block;
}
.Answer > *,.Comments > * ,.Message > * {
    width: 100%;
}
.Answer .Employee,.Comments .Employee,.Message .Employee{
    display: flex;
    justify-content: flex-end;
}
.Answer .Employee > p,.Comments .Employee > p,.Message .Employee > p{
    padding-right: 5px;
}

.Answer .Submit,.Message .Submit{
    float: right;
    width: 200px;
}

.Message, .newMessage {
    padding-top: 5px;
}
.Message .Submit{
    margin-top: 5px;
}

.Comments {
    overflow-y: auto;
    max-height: 350px;
    border: lightgray 1px solid;
    padding: 3px;
}

.Answer {
    display: flow-root;
    border: lightgray 1px solid;
    padding: 3px;
    margin-bottom: 10px;
}

.newMessage > *{
    float: right;
}
.mail > form {
    padding: 20px 20px 0px 20px;
}

.mail > form > button {
    float: right;
    margin-top:5px;
}