.time-field {
  &_simple {
    .bp3-input-group {
      min-width: 196px;
    }
  }
  .bp3-button {
    margin: 0 !important;
    padding: 0 !important;
  }
  .bp3-input {
    height: 24px !important;
  }
  .right-btn {
    padding: 4px;
  }
}
.time-range {
  padding-right: 8px;
  .bp3-input {
    max-width: 105px;
  }
}

.time-field > div:nth-child(1){
  display: flex;
  justify-content: center;
}
.time-field .bp3-timepicker-arrow-button {
  padding: 0 !important;
}

.time-field .bp3-label{
  display: flex;
}

.time-field .bp3-label > span{
  align-self: center;
}

.time-field .row-input{
  width: 80px;
}