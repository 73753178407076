.changes-details {
  width: 100%;

  &_container {
    width: 100%;
  }
  &__header {
  }
  &__body {
    margin: 4px;
    padding: 24px;
    border: 1px solid rgba(16, 107, 163, 0.6);
  }
  &__info {
    padding-bottom: 16px;
  }
  &__changes {

  }
}
.bp3-alert-contents {
  width: 100%;
}

.filter-params {
  padding: 8px 0;
  font-weight: bold;
  text-decoration: underline;
}
