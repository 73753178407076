.editable-table-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 4px;
  border: 1px solid #B0C4DE;
  border-radius: 2px;
  padding: 4px;
  &__icon {
    color: #777;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  &__form {
    //border: 1px solid #B0C4DE;
    //border-radius: 2px;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }

  .first-row {
    width: 100%;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px;

    .bp3-button {
      margin: 0 2px !important;
    }
  }

  .block-result {
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    min-height: 24px;
    padding-left: 2px;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
    0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);

    .bp3-tag {
      background: #fff !important;
      color: black;
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
      0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15),
      inset 0 1px 1px rgba(16, 22, 26, 0.2);
    }
  }
}
.text-align{
  text-align: center;
}
