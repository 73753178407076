.clear-btn-large {
  float: right;
  padding: 6px;
  color: #5c7080;
  cursor: pointer;
}
.template-panel {
  &_form{
    padding: 8px;
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 1px solid rgba(16, 107, 163, 0.5);
  }
  &_field{
    padding: 0 8px;
  }
}
.template {
  &__attach-btn {
    background: yellow;
    width: 60px;
    margin-right: 24px;
  }
}
