.transformation {
  display: flex;
  flex-direction: column;
  .buttons-actions {
    padding: 0 8px;
  }
  .transformation-label {
    padding: 8px;
  }
  .card-result {

  }
}

.card-search-dialog {
  max-width: calc(90vw);
  min-width: calc(70vw);
  // min-height: calc(85vh);
  &__content {
    padding: 24px;
  }
  &__body {
    max-height: calc(70vh);
    min-height: calc(20vh);
    overflow: auto;
  }
}
