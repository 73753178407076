.form-group-text {
  input {
    min-width: 218px;
  }
}

.detailed-view {
  margin: 2px 0;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 4px;
  width: 100%;
  border-radius: 3px;
  text-align: left;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
  0 0 0 0 rgba(19, 124, 189, 0),
  inset 0 0 0 1px rgba(16, 22, 26, 0.15),
  inset 0 1px 1px rgba(16, 22, 26, 0.2);
  position: relative;

  &__el {
    border-radius: 3px;
    border-bottom: 1px solid #eee;
    width: 100%;
    padding: 2px 0;
  }
}

