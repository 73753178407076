
.filter-model {
  
  .action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    border-bottom: 1px solid #eee;
    box-shadow: 2px 2px 2px #eee;
    width: 100%;
    button {
      margin-right: 8px;
      flex: 1;
    }
  }

  .filters {
    height: calc(100vh - 115px);
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
    padding: 8px 0 0px 16px;
  }
}
//.bp3-popover.bp3-minimal.bp3-popover{
//  width: 70%;
//  right: -30%;
//  position: relative;
//}
