
.bp3-toast-message {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  padding: 11px;
  word-break: break-word;
}
