.state-view {
    padding: 0 16px;
    font-size: 0.8rem;
    .bp3-icon {
      margin: 0px !important;
    }
    .state {
      color: #0e5a8a;
    }
  
  }