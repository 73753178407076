.display-row {
  display: flex;
  flex-direction: row;
  p, div, button {
    margin: 0 4px;
  }
}
.div-style{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.change-password-form{
  &_body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: black;
    hr{
      align-self: center;
      color: black;
      width: 85%;
    }
    &__current-password, &__new-password,&__renew-password{
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  &_body:first-child{
    margin-top: 15px;
  }
}

.input-group{
  width: 150px;
  height: 30px;
  margin: 10px 130px 0 0;
}
