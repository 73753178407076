$blue: #333;
$font-color: #182026;

// weight
$semi-bold: 400;
$bold: 600;
$very-bold: 700;

// grid
$grid-small: 4px;
$grid-standard: 8px;
$grid-double: 16px;
$grid-medium: 24px;
$grid-large: 32px;