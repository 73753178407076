.search-form {
    display: flex;
    flex-direction: row;

    .no-result {
        padding: 4px;
    }
    .result {
        padding: 0 8px;
        margin: 0 8px;
        max-height: 300px;
        min-width: 300px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__details {
            padding: 8px 0;
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: flex-start;
            .bp3-button:not(:first-child) {
                margin-left: unset !important;
            }
        }
    }
}