.by-dv {
    &__select-report {
        padding: 16px;
        display: flex;
        flex-direction: row;
    }
    &__grouping {
        padding: 0 8px 0 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    &__filters {
        align-items: center;
        justify-content: center;
        margin-right: 16px;
    }
    &__select {
        padding: 0 16px;
        margin-top: -8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    &__select-report {
        margin-right: 8px;
    }
    &__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}