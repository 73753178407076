.grid-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-spinner{
  position: absolute;
  min-height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,0.5 ) ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
}