@import "./variables.scss";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

.dialog-header {
  text-align: center;
  width: 100%;
}

.bp3-input-left-container {
  .bp3-tag {
    margin: 0px !important;
    line-height: 20px;
  }
}
.bp3-label {
  font-weight: 600;
}

.bp3-menu-item {
  user-select: unset !important;
  -webkit-user-select: unset !important;
}
