@import "~@blueprintjs/core/lib/scss/variables";
.dialog{
  width: 775px !important;
  padding: 0 !important;
  text-align: center;
  &_body{
    height: calc(100% - 40px);
  }
  //&_footer{
  //  margin: 20px !important;
  //  display:flex;
  //  flex-direction: row;
  //  justify-content: center;
  //}
}
.#{$ns}-dialog-footer{
  margin: 20px !important;
  display:flex;
  flex-direction: row;
  justify-content: center;
}
.reference-drawer {
  max-width: 250px;
}
.reference-link {
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
}


