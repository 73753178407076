.array-field {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 4px;

  .row {
  }

  .first-row {
    width: 100%;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px;

    .bp3-button {
      margin: 0 2px !important;
    }
  }

  .block-result {
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    padding-left: 2px;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
    0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);

    .bp3-tag {
      background: #fff !important;
      color: black;
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
      0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15),
      inset 0 1px 1px rgba(16, 22, 26, 0.2);
    }
  }
}
.display-none {
  display: none;
}

