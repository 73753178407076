.change-filter {
  display: flex;
  flex-direction: row;
  width: 100%;
  &_form{
    padding: 8px;
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // border: 1px solid rgba(16, 107, 163, 0.5);
  }
  &_field{
    padding: 0 8px;
  }
}

.resetBtn {
  margin-left: 0.5rem;
} 