.dialog{
  max-width: calc(90vw);
  min-width: calc(70vw);
  // min-height: calc(85vh);
  &__content {
    padding: 24px;
  }
  &__body {
    max-height: calc(70vh);
    min-height: calc(20vh);
    overflow: auto;
  }
  .footer-actions {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

  }
}

label.bp3-label {
  margin-bottom: 2px !important;
  font-size: 1rem;
}

.general-header {
  &__title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__right {
    margin-top: -18px;
    display: block;
    float: right;
    padding-bottom: 4px;
    padding-right: 4px;
  }
}
